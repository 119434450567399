<template>
  <div class="fora-device">
    <HeaderMenu/>
    <div class="container-fluid py-4">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="submitForaDeviceForm">

            <div class="mb-3">
              <label for="bedNo" class="form-label fw-bold">{{ $t("__searchOnlyBedNumber") }}</label>
              <div class="col-sm-6 col-md-4">
                <div class="input-group mb-3 rounded-pill">
                  <input
                    v-model="bedNumberFilter"
                    type="text"
                    class="form-control rounded-pill"
                    :placeholder="$t('__searchOnlyBedNumber')"
                    aria-label="search"
                    aria-describedby="search"
                  />
                  <button
                    type="submit"
                    class="btn btn-link px-2 text-dark"
                    id="search"
                  >
                    <i class="bi bi-search"></i>
                  </button>
                  <button
                    v-if="bedNumberFilter"
                    type="button"
                    class="btn btn-primary px-3 ms-1 rounded-pill"
                    id="clearSearch"
                    @click="clearBedNumberFilter"
                  >
                    {{ $t('__clearSearch') }}
                  </button>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label for="bedNo" class="form-label fw-bold">{{ $t("__searchResidentName") }}</label>
              <div class="col-sm-6 col-md-4">
                <div class="input-group mb-3 rounded-pill">
                  <input
                    v-model="residentNameFilter"
                    type="text"
                    class="form-control rounded-pill"
                    :placeholder="$t('__searchResidentName')"
                    aria-label="search"
                    aria-describedby="search"
                  />
                  <button
                    type="submit"
                    class="btn btn-link px-2 text-dark"
                    id="search"
                  >
                    <i class="bi bi-search"></i>
                  </button>
                  <button
                    v-if="residentNameFilter"
                    type="button"
                    class="btn btn-primary px-3 ms-1 rounded-pill"
                    id="clearSearch"
                    @click="clearResidentNameFilter"
                  >
                    {{ $t('__clearSearch') }}
                  </button>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label for="bedNo" class="form-label fw-bold">{{ $t("__residentName") }}</label>
              <div class="col-sm-6 col-md-4">
                <select class="form-select" v-model="from.resident_id" required>
                  <option :value="null">{{ $t('__openThisSelectMenuResident') }}</option>
                  <template v-for="data in filteredResidents">
                    <option :key="data.id" :value="data.id" v-if="data.name" >
                      {{ ((data.device === null) ? '' : data.device.bed_number) + " / " + data.name }}
                    </option>
                  </template>
                </select>
              </div>
            </div>

            <div class="mb-3">
              <label for="bedNo" class="form-label fw-bold">{{ $t("__measurementGroup") }}</label>
              <div class="col-sm-6 col-md-4 d-flex align-items-center">
                <select class="form-select me-2" v-model="from.group_name" required>
                  <option :value="null">{{ $t('__measurementGroup') }}</option>
                  <template v-for="group in foraGroups">
                    <option :key="group" :value="group">
                      {{ group }}
                    </option>
                  </template>
                </select>

                <button
                  @click="showEditForaDeviceModal()"
                  type="button"
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__edit')"
                >
                  <i class="bi bi-pencil mx-1"></i>
                </button>
              </div>
            </div>

            <button type="submit" class="btn btn-primary rounded-pill">
              {{ $t("__measurement") }}
            </button>
          </form>
        </div>
      </div>
    </div>

    <div class="container-fluid py-4">
      <div class="card">
        <div class="card-body">
          <form style="margin-bottom: 20px;" @submit.prevent="submitForaDailyReportForm">
            <div class="mb-3">
              <label for="bedNo" class="form-label fw-bold">{{ $t("__residentName") }}</label>
              <div class="col-sm-6 col-md-4">
                <select class="form-select" v-model="dailyForm.resident_id">
                  <option :value="null">{{ $t('__openThisSelectMenuResident') }}</option>
                  <template v-for="data in filteredResidents">
                    <option :key="data.id" :value="data.id" v-if="data.name" >
                      {{ ((data.device === null) ? '' : data.device.bed_number) + " / " + data.name }}
                    </option>
                  </template>
                </select>
              </div>
            </div>

            <div class="mb-3">
              <label class="form-label fw-bold">{{ $t('__date') }}</label>
              <div class="d-flex align-items-center flex-wrap">
                <div class="d-inline-block pe-sm-4 mb-2">
                  <VueCtkDateTimePicker
                    :label="$t('__startDate')"
                    v-model="dailyForm.start_date"
                    only-date
                    format="YYYY-MM-DD"
                    formatted="YYYY-MM-DD"
                    color="#2CBDC0"
                    button-color="#2CBDC0"
                    :max-date="new Date().format('yyyy-MM-dd')"
                    :min-date="new Date().addDays(-29).format('yyyy-MM-dd')"
                  />
                  <div class="text-danger" v-if="is_invalid.start_date">
                    {{ feedback_message.start_date }}
                  </div>
                </div>
                <div class="d-inline-block pe-sm-4 mb-2">
                  <VueCtkDateTimePicker
                    :label="$t('__endDate')"
                    v-model="dailyForm.end_date"
                    only-date
                    format="YYYY-MM-DD"
                    formatted="YYYY-MM-DD"
                    color="#2CBDC0"
                    button-color="#2CBDC0"
                    :max-date="new Date().format('yyyy-MM-dd')"
                    :min-date="dailyForm.start_date"
                  />
                  <div class="text-danger" v-if="is_invalid.end_date">
                    {{ feedback_message.end_date }}
                  </div>
                </div>
              </div>
            </div>

            <button type="submit" class="btn btn-primary rounded-pill">
              {{ $t("__submit") }}
            </button>

            <button v-if="reportData.length > 0" style="margin-left: 10px;" @click="exportToCSV" class="btn btn-primary rounded-pill">
              {{ $t("__exportCSV") }}
            </button>
          </form>

          <div v-if="reportData.length === 0 && displayDailyReport === true">
            {{ $t('__noDate') }}
          </div>
          <b-table
                  v-if="reportData.length > 0"
                  responsive
                  striped
                  borderless
                  head-variant="light"
                  :empty-text="$t('__notData')"
                  :empty-filtered-text="$t('__notFindDevice')"
                  :items="reportData"
                  :fields="reportFields"
          >
            <template v-slot:cell(resident_id)="data">
              <span v-if="data.item.resident">
                {{ data.item.resident.id }}
              </span>
            </template>

            <template v-slot:cell(resident_name)="data">
              <span v-if="data.item.resident">
                {{ data.item.resident.name }}
              </span>
            </template>

            <template v-slot:cell(type)="data">
                    <span v-if="data.item.type === 'glucose'">
                      {{ $t('__chilineDeviceGlucose') }}
                    </span>
              <span v-else-if="data.item.type === 'oxygen'">
                      {{ $t('__chilineTypeOxygen') }}
                    </span>
              <span v-else-if="data.item.type === 'temperature'">
                      {{ $t('__foraDeviceForeheadTemperature') }}
                    </span>
              <span v-else-if="data.item.type === 'ear_temperature'">
                      {{ $t('__foraDeviceEarTemperature') }}
                    </span>
              <span v-else-if="data.item.type === 'pressure'">
                      {{ $t('__chilineDevicePressure') }}
                    </span>
              <span v-else-if="data.item.type === 'ketone'">
                      {{ $t('__chilineDeviceKetone') }}
                    </span>
              <span v-else-if="data.item.type === 'uric_acid'">
                      {{ $t('__chilineDeviceUricAcid') }}
                    </span>
              <span v-else-if="data.item.type === 'cholesterol'">
                      {{ $t('__chilineDeviceCholesterol') }}
                    </span>
              <span v-else>
                      {{ $t('__chilineDeviceNoType') }}
                    </span>
            </template>

            <template v-slot:cell(group_name)="data">
              <span v-if="data.item.fora_user">
                {{ data.item.fora_user.group_name }}
              </span>
            </template>

            <template v-slot:cell(value)="data">
                    <span v-if="data.item.type === 'glucose'">
                      {{ $t('__chilineDeviceGlucose') }}: {{ data.item.MValue1 }} / {{ $t('__foraHemoglobin') }}: {{ data.item.MValue2 * 0.34 }} / {{ $t('__foraHematocrit') }}: {{ data.item.MValue2 }}
                    </span>
              <span v-else-if="data.item.type === 'oxygen'">
                      {{ $t('__foraAverageBloodOxygenLevel') }}: {{ data.item.MValue1 }} {{ $t('__percent_min') }} / {{ $t('__foraHeartbeat') }}: {{ data.item.MValue2 }} {{ $t('__times_min') }}
                    </span>
              <span v-else-if="data.item.type === 'temperature'">
                      {{ $t('__chilineDeviceTemperature') }}: {{ data.item.MValue1 }}
                    </span>
              <span v-else-if="data.item.type === 'ear_temperature'">
                      {{ $t('__chilineDeviceTemperature') }}: {{ data.item.MValue1 }}
                    </span>
              <span v-else-if="data.item.type === 'pressure'">
                      {{ $t('__foraDiastolicPressure') }}: {{ data.item.MValue1 }} {{ $t('__foraPressureUnit') }} /
                      {{ $t('__foraSystolicPressure') }}: {{ data.item.MValue2 }} {{ $t('__foraPressureUnit') }} /
                      {{ $t('__foraHeartbeat') }}: {{ data.item.MValue3 }} {{ $t('__times_min') }}
                    </span>
              <span v-else-if="data.item.type === 'ketone'">
                      {{ $t('__foraKetone') }}: {{ data.item.MValue1 }}
                    </span>
              <span v-else-if="data.item.type === 'uric_acid'">
                      {{ $t('__chilineDeviceUricAcid') }}: {{ data.item.MValue1 }}
                    </span>
              <span v-else-if="data.item.type === 'cholesterol'">
                      {{ $t('__foraCholesterol') }}: {{ data.item.MValue1 }}
                    </span>
              <span v-else>
                      {{ $t('__chilineDeviceNoType') }}
                    </span>
            </template>
          </b-table>
        </div>
      </div>
    </div>

    <!-- 編輯群組 Model -->
    <div
      class="modal fade cms-style"
      tabindex="-1"
      id="editForaDevice"
      ref="editForaDeviceModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitEditGroupForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium text-center">
                <span class="d-block"></span>
                <span class="d-block">{{ $t('__editDevice') }}</span>
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <b-table
                  responsive
                  striped
                  borderless
                  head-variant="light"
                  :empty-text="$t('__notData')"
                  :empty-filtered-text="$t('__notFindDevice')"
                  :items="foraDevices"
                  :fields="fields"
                >
                  <template v-slot:cell(type)="data">
                    <span v-if="data.item.type === 'glucose'">
                      {{ $t('__chilineDeviceGlucose') }}
                    </span>
                    <span v-else-if="data.item.type === 'oxygen'">
                      {{ $t('__chilineTypeOxygen') }}
                    </span>
                    <span v-else-if="data.item.type === 'temperature'">
                      {{ $t('__foraDeviceForeheadTemperature') }}
                    </span>
                    <span v-else-if="data.item.type === 'ear_temperature'">
                      {{ $t('__foraDeviceEarTemperature') }}
                    </span>
                    <span v-else-if="data.item.type === 'pressure'">
                      {{ $t('__chilineDevicePressure') }}
                    </span>
                    <span v-else-if="data.item.type === 'ketone'">
                      {{ $t('__chilineDeviceKetone') }}
                    </span>
                    <span v-else-if="data.item.type === 'uric_acid'">
                      {{ $t('__chilineDeviceUricAcid') }}
                    </span>
                    <span v-else-if="data.item.type === 'cholesterol'">
                      {{ $t('__chilineDeviceCholesterol') }}
                    </span>
                    <span v-else>
                      {{ $t('__chilineDeviceNoType') }}
                    </span>
                  </template>

                  <template v-slot:cell(group_name)="data">
                    <select class="form-select" v-model="data.item.group_name" required>
                      <option value="A">A組</option>
                      <option value="B">B組</option>
                      <option value="C">C組</option>
                      <option value="D">D組</option>
                      <option value="E">E組</option>
                    </select>
                  </template>
                </b-table>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-primary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t('__cancel') }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t('__save') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- measurementModel 量測 Model -->
    <div
      class="modal fade cms-style"
      tabindex="-1"
      id="measurementModel"
      ref="measurementModel"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitMeasurementForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium text-center">
                <span class="d-block" v-if="selectedResident !== null">
                  {{
                    selectedResident.device === null ? selectedResident.name + ' / ' + from.group_name :  selectedResident.device.bed_number + ' / ' + selectedResident.name  + ' / ' + from.group_name
                  }}
                </span>
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <b-table
                  responsive
                  striped
                  borderless
                  head-variant="light"
                  :empty-text="$t('__notData')"
                  :empty-filtered-text="$t('__notFindDevice')"
                  :items="filteredForaDevices"
                  :fields="measureFields"
                >
                  <template v-slot:cell(type)="data">
                    <span v-if="data.item.type === 'glucose'">
                      {{ $t('__chilineDeviceGlucose') }}
                    </span>
                    <span v-else-if="data.item.type === 'oxygen'">
                      {{ $t('__chilineTypeOxygen') }}
                    </span>
                    <span v-else-if="data.item.type === 'temperature'">
                      {{ $t('__foraDeviceForeheadTemperature') }}
                    </span>
                    <span v-else-if="data.item.type === 'ear_temperature'">
                      {{ $t('__foraDeviceEarTemperature') }}
                    </span>
                    <span v-else-if="data.item.type === 'pressure'">
                      {{ $t('__chilineDevicePressure') }}
                    </span>
                    <span v-else-if="data.item.type === 'ketone'">
                      {{ $t('__chilineDeviceKetone') }}
                    </span>
                    <span v-else-if="data.item.type === 'uric_acid'">
                      {{ $t('__chilineDeviceUricAcid') }}
                    </span>
                    <span v-else-if="data.item.type === 'cholesterol'">
                      {{ $t('__chilineDeviceCholesterol') }}
                    </span>
                    <span v-else>
                      {{ $t('__chilineDeviceNoType') }}
                    </span>
                  </template>

                  <template v-slot:cell(value)="data">
                    {{ data.item.value ? data.item.value : '--' }}
                  </template>
                </b-table>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-primary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t('__cancel') }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t('__save') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import HeaderMenu from '@/components/layout/HeaderMenu.vue';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import i18n from '@/lang/lang.js';
import { resident } from '@/http/api/resident.js';
import { fora } from '@/http/api/fora.js';
import { mapMutations, mapState } from 'vuex';
import { Modal } from 'bootstrap';
import Echo from 'laravel-echo';
import io from 'socket.io-client';

export default {
    name: 'SearchReportResident',
    components: { HeaderMenu, VueCtkDateTimePicker },
    data() {
        return {
            resident: [],
            foraDevices: null,
            foraGroups: null,
            search: null,
            filter: null,
            bedNumberFilter: '',
            residentNameFilter: '',
            from: {
                resident_id: null,
                group_name: null
            },
            dataTypeAll: true,
            notifyTypeAll: false,
            feedback_message: {
                date: i18n.t('__dateIsRequired')
            },
            editGroupForm: {},
            fields: [
                {
                    key: 'MDeviceId',
                    label: i18n.t('__deviceId'),
                    class: 'align-middle',
                    thStyle: 'width:120px'
                },
                {
                    key: 'type',
                    label: i18n.t('__chilineDeviceType'),
                    class: 'align-middle',
                    thStyle: 'width:120px'
                },
                {
                    key: 'group_name',
                    label: i18n.t('__measurementGroup'),
                    class: 'align-middle'
                }
            ],
            measureFields: [
                {
                    key: 'MDeviceId',
                    label: i18n.t('__deviceId'),
                    class: 'align-middle',
                    thStyle: 'width:120px'
                },
                {
                    key: 'type',
                    label: i18n.t('__chilineDeviceType'),
                    class: 'align-middle',
                    thStyle: 'width:120px'
                },
                {
                    key: 'group_name',
                    label: i18n.t('__measurementGroup'),
                    class: 'align-middle'
                },
                {
                    key: 'value',
                    label: i18n.t('__measurementValue'),
                    class: 'align-middle'
                }
            ],
            reportFields: [
                {
                    key: 'MPDateTime',
                    label: i18n.t('__foraMeasurementTime'),
                    class: 'align-middle',
                    thStyle: 'width:120px'
                },
                {
                    key: 'resident_id',
                    label: i18n.t('__residentId'),
                    class: 'align-middle'
                },
                {
                    key: 'resident_name',
                    label: i18n.t('__residentName'),
                    class: 'align-middle'
                },
                {
                    key: 'type',
                    label: i18n.t('__chilineDeviceType'),
                    class: 'align-middle',
                    thStyle: 'width:120px'
                },
                {
                    key: 'group_name',
                    label: i18n.t('__measurementGroup'),
                    class: 'align-middle'
                },
                {
                    key: 'value',
                    label: i18n.t('__measurementValue'),
                    class: 'align-middle'
                }
            ],
            collectedEvents: [],
            dailyForm: {
                resident_id: null,
                start_date: new Date().format('yyyy-MM-dd'),
                end_date: new Date().format('yyyy-MM-dd')
            },
            reportData: [],
            displayDailyReport: false,
            is_invalid: {
                start_date: false,
                end_date: false
            }
        };
    },
    computed: {
        ...mapState(['token', 'timezone', 'userInfo', 'echoClient']),
        filteredForaDevices() {
            const vm = this;
            if (!vm.from.group_name) {
                return [];
            }
            return vm.foraDevices.filter(device => device.group_name === vm.from.group_name);
        },
        selectedResident() {
            const vm = this;
            if (!vm.from.resident_id || !vm.resident) {
                return null;
            }
            return vm.resident.find(r => r.id === vm.from.resident_id) || null;
        },
        filteredResidents() {
            return this.resident.filter((data) => {
                const matchesBedNumber =
          this.bedNumberFilter === '' ||
          (data.device && data.device.bed_number.includes(this.bedNumberFilter));
                const matchesResidentName =
          this.residentNameFilter === '' ||
          data.name.toLowerCase().includes(this.residentNameFilter.toLowerCase());

                return matchesBedNumber && matchesResidentName;
            });
        },
        dailyMaxDate() {
            const vm = this;
            const hours = new Date(vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD HH:mm')).getHours();
            let maxDate = '';
            maxDate = hours >= 12
                ? vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD')
                : vm.$getTimeZoneDate(new Date().addDays(-1), vm.timezone, 'YYYY-MM-DD');
            return maxDate;
        },
        dailyMinDate() {
            const vm = this;
            const hours = new Date(vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD HH:mm')).getHours();
            let minDate = '';
            minDate = hours >= 12
                ? vm.$getTimeZoneDate(new Date().addDays(-29), vm.timezone, 'YYYY-MM-DD')
                : vm.$getTimeZoneDate(new Date().addDays(-30), vm.timezone, 'YYYY-MM-DD');
            return minDate;
        }
    },
    watch: {
        userInfo() {
            const vm = this;
            vm.mesaurementSocket();
            vm.getResident();
            vm.getForaDevice();
        }
    },
    methods: {
        ...mapMutations([
            'AlertsInfo',
            'Alerted'
        ]),
        getResident() {
            const vm = this;
            resident.get('', vm.token).then((res) => {
                if (res.status <= 201 && res.data.status === 'success') {
                    const data = res.data.data;
                    vm.resident = data;
                } else {
                    vm.AlertsInfo({
                        state: 'error',
                        title: i18n.t('__error'),
                        info: res.data.errors.toString()
                    });
                    vm.Alerted();
                }
            });
        },
        getForaDevice() {
            const vm = this;
            fora.get('', vm.token).then((res) => {
                if (res.status <= 201 && res.data.status === 'success') {
                    vm.foraDevices = res.data.data;
                    vm.foraGroups = vm.foraDevices
                        .map(device => device.group_name)
                        .filter((groupName, index, self) => groupName && self.indexOf(groupName) === index);
                } else {
                    vm.AlertsInfo({
                        state: 'error',
                        title: i18n.t('__error'),
                        info: res.data.errors.toString()
                    });
                    vm.Alerted();
                }
            });
        },
        showEditForaDeviceModal() {
            const vm = this;
            vm.$nextTick(function() {
                vm.bootstrap_modal = new Modal(vm.$refs.editForaDeviceModal);
                vm.bootstrap_modal.show();
            });
        },
        submitForaDeviceForm() {
            const vm = this;
            fora.realtimeMeasurement({
                agency_id: vm.userInfo.agency_id,
                resident_id: vm.from.resident_id,
                group_name: vm.from.group_name
            }, vm.token).then((res) => {
                if (res.status <= 201 && res.data.status === 'success') {
                    vm.showMeasurementModel();
                } else {
                    vm.AlertsInfo({
                        state: 'error',
                        title: i18n.t('__error'),
                        info: res.data.errors.toString()
                    });
                    vm.Alerted();
                }
            });
        },
        mesaurementSocket() {
            const vm = this;
            vm.mesaurementEchoClient = new Echo({
                broadcaster: 'socket.io',
                host: 'https://' + window.location.hostname,
                client: io,
                options: {
                    reconnection: true,
                    reconnectionDelay: 1000,
                    reconnectionDelayMax: 5000,
                    timeout: 30000,
                    transports: ['websocket', 'polling']
                }
            });

            vm.mesaurementEchoClient.connector.socket.on('disconnect', () => {
                console.log('socket disconnect');
            });
            vm.mesaurementEchoClient.connector.socket.on('connect', () => {
                console.log('socket connect');
            });

            vm.mesaurementEchoClient.channel('broadcast_database_ForaEvent.' + vm.userInfo.agency.id)
                .listen('ForaEvent', (event) => {
                    vm.filteredForaDevices.forEach((device, index) => {
                        if (
                            device.MDeviceId === event.fora.MDeviceID &&
              device.type === event.fora.type &&
              device.group_name === event.fora.group_name
                        ) {
                            vm.$set(device, 'value', event.fora.value);

                            // 检查 collectedEvents 中是否已有相同的 MDeviceId 和 type
                            const existingIndex = vm.collectedEvents.findIndex(
                                (collectedEvent) =>
                                    collectedEvent.MDeviceId === event.fora.MDeviceID &&
                  collectedEvent.type === event.fora.type
                            );

                            // 如果已存在，移除旧的记录
                            if (existingIndex !== -1) {
                                vm.collectedEvents.splice(existingIndex, 1);
                            }

                            // 添加新的记录
                            vm.collectedEvents.push({
                                MDeviceId: event.fora.MDeviceID,
                                type: event.fora.type,
                                group_name: event.fora.group_name,
                                value: event.fora.value
                            });
                        }
                    });
                });
        },
        submitEditGroupForm() {
            const vm = this;
            vm.editGroupForm = vm.foraDevices.map(device => ({
                id: device.MDeviceId,
                group_name: device.group_name,
                type: device.type
            }));

            fora.updateGroup(vm.editGroupForm, vm.token).then((res) => {
                if (res.status <= 201 && res.data.status === 'success') {
                    vm.AlertsInfo({
                        state: 'success',
                        title: i18n.t('__success'),
                        info: i18n.t('__editSuccess')
                    });
                    vm.Alerted();
                    vm.getForaDevice();
                    vm.bootstrap_modal.hide();
                } else {
                    vm.AlertsInfo({
                        state: 'error',
                        title: i18n.t('__error'),
                        info: res.data.errors.toString()
                    });
                    vm.Alerted();
                }
            });
        },
        showMeasurementModel() {
            const vm = this;
            vm.$nextTick(function() {
                vm.measurementModal = new Modal(vm.$refs.measurementModel);
                vm.measurementModal.show();
            });
        },
        submitMeasurementForm() {
            const vm = this;

            const isConfirmed = window.confirm(i18n.t('__confirmEndMeasurement'));
            if (!isConfirmed) {
                return;
            }

            if (vm.collectedEvents.length === 0) {
                vm.AlertsInfo({
                    state: 'error',
                    title: i18n.t('__error'),
                    info: i18n.t('__noMmeasurementData')
                });
                vm.Alerted();
                return;
            }

            fora.updateRealtimeMeasurement({
                resident_id: vm.selectedResident.id,
                data: vm.collectedEvents
            }, vm.token).then((res) => {
                if (res.status <= 201 && res.data.status === 'success') {
                    vm.AlertsInfo({
                        state: 'success',
                        title: i18n.t('__success'),
                        info: i18n.t('__measurementDataSaveSuccess')
                    });
                    vm.Alerted();
                    vm.collectedEvents = [];
                    vm.filteredForaDevices.forEach((device, index) => {
                        vm.$set(device, 'value', null);
                    });
                    vm.measurementModal.hide();
                } else {
                    vm.AlertsInfo({
                        state: 'error',
                        title: i18n.t('__error'),
                        info: i18n.t('__measurementDataSaveFail')
                    });
                    vm.Alerted();
                }
            });
        },
        clearBedNumberFilter() {
            this.bedNumberFilter = '';
        },
        clearResidentNameFilter() {
            this.residentNameFilter = '';
        },
        submitForaDailyReportForm() {
            fora.dailyReport(this.token, this.dailyForm).then((res) => {
                if (res.status <= 201 && res.data.status === 'success') {
                    this.reportData = res.data.data;
                    this.displayDailyReport = false;

                    if (this.reportData.length === 0) {
                        this.displayDailyReport = true;
                    }
                } else {
                    this.AlertsInfo({
                        state: 'error',
                        title: i18n.t('__error'),
                        info: res.data.errors.toString()
                    });
                    this.Alerted();
                }
            });
        },
        exportToCSV() {
            const headers = this.reportFields.map(field => field.label).join(',') + '\n';

            const rows = this.reportData.map(item => {
                return [
                    item.MPDateTime ? item.MPDateTime : '',
                    item.resident ? item.resident.id : '',
                    item.resident ? item.resident.name : '',
                    this.getTypeLabel(item.type),
                    item.fora_user ? item.fora_user.group_name : '',
                    this.getValueLabel(item)
                ].join(',');
            }).join('\n');

            const csvContent = headers + rows;
            const bom = '\uFEFF';

            const blob = new Blob([bom + csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', 'report_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        getTypeLabel(type) {
            switch (type) {
            case 'glucose': return this.$t('__chilineDeviceGlucose');
            case 'oxygen': return this.$t('__chilineTypeOxygen');
            case 'temperature': return this.$t('__foraDeviceForeheadTemperature');
            case 'ear_temperature': return this.$t('__foraDeviceEarTemperature');
            case 'pressure': return this.$t('__chilineDevicePressure');
            case 'ketone': return this.$t('__chilineDeviceKetone');
            case 'uric_acid': return this.$t('__chilineDeviceUricAcid');
            case 'cholesterol': return this.$t('__chilineDeviceCholesterol');
            default: return this.$t('__chilineDeviceNoType');
            }
        },
        getValueLabel(item) {
            switch (item.type) {
            case 'glucose':
                return `${this.$t('__chilineDeviceGlucose')}: ${item.MValue1} / ${this.$t('__foraHemoglobin')}: ${item.MValue2 * 0.34} / ${this.$t('__foraHematocrit')}: ${item.MValue2}`;

            case 'oxygen':
                return `${this.$t('__foraAverageBloodOxygenLevel')}: ${item.MValue1} ${this.$t('__percent_min')} / ${this.$t('__foraHeartbeat')}: ${item.MValue2} ${this.$t('__times_min')}`;

            case 'temperature':
            case 'ear_temperature':
                return `${this.$t('__chilineDeviceTemperature')}: ${item.MValue1}`;

            case 'pressure':
                return `${this.$t('__foraDiastolicPressure')}: ${item.MValue1} ${this.$t('__foraPressureUnit')} / ${this.$t('__foraSystolicPressure')}: ${item.MValue2} ${this.$t('__foraPressureUnit')} / ${this.$t('__foraHeartbeat')}: ${item.MValue3} ${this.$t('__times_min')}`;

            case 'ketone':
                return `${this.$t('__foraKetone')}: ${item.MValue1}`;

            case 'uric_acid':
                return `${this.$t('__chilineDeviceUricAcid')}: ${item.MValue1}`;

            case 'cholesterol':
                return `${this.$t('__foraCholesterol')}: ${item.MValue1}`;

            default:
                return this.$t('__chilineDeviceNoType');
            }
        }
    },
    created() {
    },
    mounted() {
        if (this.userInfo) {
            this.mesaurementSocket();
            this.getResident();
            this.getForaDevice();
        }
    }
};
</script>

<style lang="scss">
.fora-device {
  .card {
    position: relative;

    &-body {
      position: relative;
      z-index: 1;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("~@/assets/images/icon.svg");
      background-repeat: no-repeat;
      background-position: calc(100% + 20px) calc(100% + 20px);
      background-size: auto;
      opacity: 0.1;
      z-index: 0;
    }
  }
}
</style>
