import { axiosConfig } from './axios-config.js';
export const fora = {
    // 取得裝置清單
    get: (id, token) => axiosConfig.get(`/api/fora/${id || ''}`, token),

    realtimeMeasurement: (data, token) => axiosConfig.post('/api/fora/realtime-measurement', data, token),

    // 更新裝置資訊
    update: (id, data, token) => axiosConfig.patch(`/api/device/${id}`, data, token),

    updateGroup: (data, token) => axiosConfig.patch('/api/fora/group', data, token),

    updateRealtimeMeasurement: (data, token) => axiosConfig.post('/api/fora/update-realtime-measurement', data, token),

    dailyReport: (token, additionalParams = {}) => {
        return axiosConfig.get('/api/fora/daily-report', { headers: token.headers, params: additionalParams });
    },

    ecgReport: (token, additionalParams = {}) => {
        return axiosConfig.get('/api/fora/ecg-report', { headers: token.headers, params: additionalParams });
    },

    ecgReportDetail: (id, token) => {
        return axiosConfig.get(`/api/fora/ecg-report/${id}`, { headers: token.headers });
    }
};
